<template>
<v-container fluid class="footer">
    <v-row>
        <v-col cols="12">
            <v-container>
                <v-row>
                    <v-col cols="12" md="3" sm="6">
                        <v-row>
                            <v-col cols="12" class="text">
                                <ul>
                                    <li>
                                        <a href="#" @click.prevent="goTo('/privacy_policy')">Políticas de privacidade</a>
                                    </li>
                                    <li>
                                        <a href="#" @click.prevent="goTo('/shipping_policy')">Política de envios</a>
                                    </li>
                                    <li>
                                        <a href="" @click.prevent="goTo('/exchanges_and_returns')">Trocas e devoluções</a>
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="12" class="seal">
                                <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/selos/lets-encrypt-188edc924987e0c961ceba7d295770752756a433893eb6a5f63cc0040f9a493b.svg" alt="">
                                <img @click="goRef" src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/selos/google-security-a054f4a5d63e352db1c52360c3ff1d9c9c7b55affe6accf357674096beababb5.svg" alt="">
                            </v-col>
                            <v-col cols="12" class="social">
                                <v-container fluid>
                                        <v-row>
                                            <v-col v-if="storeData.twitterLink">
                                                <a :href="storeData.twitterLink ? storeData.twitterLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="blue" class="social-icon">mdi-twitter</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.facebookLink">
                                                <a :href="storeData.facebookLink ? storeData.facebookLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="blue darken-2"
                                                        class="social-icon">mdi-facebook</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.instagramLink">
                                                <a :href="storeData.instagramLink ? storeData.instagramLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="purple" class="social-icon">mdi-instagram</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.youtubeLink">
                                                <a :href="storeData.youtubeLink ? storeData.youtubeLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="red" class="social-icon">mdi-youtube</v-icon>
                                                </a>
                                            </v-col>
                                        </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6" v-if="!isMobile"></v-col>
                    <v-col>
                        <p style="padding-top: 20px;">
                            {{storeData.name}}<br>
                            {{storeData.address}}<br>
                            {{storeData.city}} - {{storeData.state}}<br>
                            {{storeData.zipCode}}<br>
                            {{storeData.cpf}}
                        </p>
                    </v-col>
                </v-row>
                <v-row class="copy">
                    <v-col cols="12" md="4" class="text">
                       
                    
                        <br><small>Desenvolvido por <a href="https://beeasy.digital/" target="_blank" style="text-decoration: none;color:#fff301;"><strong>Beeasy Digital</strong></a></small></p>
                    </v-col>
                    <v-col cols="12" md="4" v-if="!isMobile"></v-col>
                    <v-col class="payments">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/visa-4c562b0e312e36ce0daadaf465d3759ca162cb39c6a828454a5cfb2c95f8e26a.svg" alt="">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/master-f27cb6ce5923f7f52ceded3fdc486079492ac922931c00db634211bb5453b11c.svg" alt="">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/elo-c40efbc3640e09e5b4acd03ee7f09dd31d521959516adf224f007458739d77e3.svg" alt="">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/amex-6f16117e3c9e8a546737b6951c187f2014009b8b40e374dc0c846561ea66c663.svg" alt="">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/dinners-32c627a8ea96ce8e10b78feafe65bb95ae948af63539dcb9fea45a8c376a419f.svg" alt="">
                                    <img src="https://d2u4gk28rgr5ys.cloudfront.net/assets/icons/cards/pix-39099f2f23f9b0fcc7e66c2759d247b7f04e7bd44b8b8f1103aaa2ee28c0f86d.svg" alt="">
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        isMobile: Boolean
    },
    data(){
        return{
            storeData: {},
            year: new Date().getFullYear()
        }
    },
    async mounted(){    
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
    },
    methods:{
        goRef(){
            window.open('https://transparencyreport.google.com/safe-browsing/search?url=oculos2w.com.br', '_blank')
        },
        goTo(path){
            this.$router.push(this.$route.params.storeSlug == undefined ? `${path}` : `/${this.$route.params.storeSlug}${path}`)
        },
    }
}
</script>

<style scoped>
.footer {
    padding-top: 70px;
    background-color: #1C1A1D;
    color: #fff;
}

.footer .logo img {
    width: 50%;
}

.footer .text {
    font-family: Exo;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left
}

.social-icon {
    font-size: 30px;
    cursor: pointer;
}

.copy .text {
    padding-top:20px;
}

.payments img {
    width: 50px;
    margin-right: 10px;
}

ul{
    list-style-type: none;
    padding-left: 0;
}

ul li{
    padding: 5px 0;
}

ul li a{
    color: #fff;
    text-decoration: none;
}

.seal img{
    cursor: pointer;
    width: 70px;
    margin-top: 20px;
    margin-right: 30px;
}
</style>